import { FC, ReactNode } from 'react'
import cx from 'classnames'

import styles from './style.module.scss'
import { Label } from 'ds/components/Label'
import { InputInfo } from '../InputInfo'

import { FormContext } from 'lib/hooks/useForm'

type Props = {
    className?: string
    label?: ReactNode
    required?: boolean
    value: string | number
    help?: ReactNode
    error?: string
    children: React.ReactNode
    formContext?: FormContext
    disabled?: boolean
    labelClassName?: string

    onChange: (value: string) => void
}

export const Select: FC<Props> = ({
    className,
    label,
    required,
    value,
    error,
    help,
    children,
    formContext,
    disabled,
    labelClassName,
    onChange,
    ...props
}) => {
    return (
        <div className={className}>
            {label ? (
                <Label
                    className={labelClassName}
                    required={required || formContext?.required}
                >
                    {label}
                </Label>
            ) : null}
            <select
                className={cx(styles.select, 'pointer', 'ease')}
                disabled={disabled}
                value={value}
                onChange={(e) => {
                    const val = e.target.value
                    onChange(val)
                }}
                {...props}
            >
                {children}
            </select>
            <InputInfo error={error || formContext?.displayError} help={help} />
        </div>
    )
}

export const Option = ({ value, children }) => {
    return <option value={value}>{children}</option>
}
